@font-face {
    font-family: "TTNormsRegular";
    font-style: normal;
    src: local("TTNormsRegular"),
        url("./fonts/TTNorms-Regular.otf") format("opentype");
}

@font-face {
    font-family: "TTNormsBold";
    font-style: bold;
    src: local("TTNormsBold"),
        url("./fonts/TTNorms-Bold.otf") format("opentype");
}
body {
    padding: 0px;
    margin: 0px;
}

a {
    text-decoration: none !important;
}

a:active {
    color: #960019 !important;
}

.MuiTypography-paragraph {
    color: #222222 !important;
    font-family: "TTNormsRegular" !important;
    font-size: 18px !important;
}

* {
    font-family: "TTNormsRegular" !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "TTNormsBold" !important;
    color: #000000;
}

.MuiFormControl-root {
    margin: 1.5% 0% !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid !important;
}

.MuiOutlinedInput-notchedOutline {
    border-radius: 10px !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #000000 !important;
}

/*
* Button
*/
.send-data-button {
    border-radius: 10px !important;
    border: none !important;
    margin-top: 2% !important;
    padding: 1.5% 0% !important;
}

.submit-button {
    background-color: #ce043d !important;
    border-radius: 10px !important;
    border: none !important;
    margin-top: 2% !important;
    color: #ffffff !important;
}

.submit-button:hover {
    background-color: #960019 !important;
    color: #ffffff !important;
}

.signin-button {
    border-radius: 5px !important;
    margin: 0% 1% !important;
    padding: 1% 3% !important;
    border: 1px solid #960019 !important;
    color: #960019 !important;
    transition: 0.5s !important;
}

.signin-button:hover {
    background-color: #960019 !important;
    color: #ffffff !important;
}

.signin-button-white {
    border-radius: 5px !important;
    margin: 0% 1% !important;
    padding: 1% !important;
    border: 1px solid #ffffff !important;
    color: #ffffff !important;
    transition: 0.5s !important;
}

.signin-button-white:hover {
    border: 1px solid #ffffff !important;
    background-color: #ffffff !important;
    color: #960019 !important;
}

.signin-button-bg {
    border-radius: 5px !important;
    margin: 0% 1% !important;
    padding: 2% !important;
    border: 1px solid #ffffff !important;
    color: #ffffff !important;
    transition: 0.5s !important;
}

.signin-button-bg:hover {
    background-color: #ffffff !important;
    color: #cf053e !important;
}

.half-b {
    padding: 3.5% !important;
    width: 95% !important;
}

.mini-b {
    padding: 1% !important;
    margin-top: 2% !important;
}

.add-b {
    padding: 1% !important;
    margin-top: 0% !important;
}

.third-i {
    width: 95% !important;
}

.p-button {
    padding: 3.3% !important;
}

/*Back Button*/
.back {
    background-color: #a7a7a7 !important;
    border-radius: 5px !important;
    border: none !important;
    margin-top: 2% !important;
    color: #ffffff !important;
}

/*
* Instiution Onboarding
**/
.l-type {
    border-radius: 10px;
    cursor: pointer;
    color: gray;
}

.l-type:hover * {
    color: #ce043d;
}

.l-type-current {
    background-color: #ce043d;
    color: #ffffff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.l-type-current p, .l-type-current * {
    color: #ffffff !important;
}

.l-type-complete {
}

/*
* Institution SetUp
*/
.f-type {
    border: 2px solid transparent;
    border-radius: 10px !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    margin: 5%;
    text-align: center;
    transition: 0.5s;
    cursor: pointer;
    padding: 5% 1%;
}

.f-type:hover {
    border: 2px solid#CF053E;
    transform: scale(1.01);
}

.f-type:hover h2 {
    color: #cf053e !important;
}

.f-type img {
    margin-top: 8%;
    width: 100px;
    height: auto;
}

.f-selected {
    border: 2px solid#CF053E;
}

.f-selected h2 {
    color: #cf053e !important;
}

/* Payment Option */
.f-payment {
    border-radius: 10px !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    margin: 2% 0%;
}

/*ScrollBar*/
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(222, 222, 222);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ce043d;
}

.s-inactive {
    color: #bdbdbd;
}

.s-active {
    color: #ffffff;
}

.list-item {
    border-bottom: 3px solid #cf053e !important;
}

.dash-item {
    background-color: #ffffff;
    border-radius: 10px !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    padding: 5%;
    margin: 1%;
}

.MuiStepLabel-iconContainer {
    color: #fea23b !important;
}

/*
*------------------------------------------------------
* MAIN MENU
-------------------------------------------------------
*/

.main-menu {
    z-index: 1000;
    position: fixed;
}

.main-menu .MuiListItem-root {
    width: 100%;
    padding: 5%;
}

.top-menu ul {
    padding: 0px;
}

.top-menu ul a {
    color: #444444;
}

.top-menu ul .MuiListItem-root {
    width: auto;
    padding-top: 3px;
    padding-bottom: 0px;
}

.top-menu .MuiSvgIcon-root {
    font-size: 18px;
}

.top-side-menu .MuiListItem-root {
    padding: 2%;
}

.top-side-menu .signin-button {
    padding: 3% !important;
    width: 100% !important;
}

.top-side-menu .accent-button {
    padding: 3% !important;
    width: 100% !important;
}

.top-side-menu a {
    color: #cf053e;
}

.main-menu .MuiMenu-paper {
    top: 7% !important;
}

.small-screen {
    display: none;
}

.accent-button {
    background: #324c74 !important;
    border: none;
    padding: 1% 3% !important;
    color: white !important;
}

.accent-button:active {
    color: white !important;
}

.accent-button:hover {
    background-color: #960019 !important;
}

/*
*--------------------------------------------------------
* HOME PAGE
*--------------------------------------------------------
*/

/* welcome-section */
.welcome-section {
    background: url("https://ik.imagekit.io/9vm1rbs0vzz/public/WapiDoc_B5ZxB_hnp.jpg");
    display: inline-block;
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* search-form */
.search-form {
    text-align: center !important;
    padding: -1% !important;
    margin: 30% auto 0% auto;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
    width: 80% !important;
}

.search-form .MuiInputBase-input {
    padding: 2.5% 3%;
    font-size: 22px !important;
    text-transform: lowercase;
}

.search-form .submit-button {
    padding: 6% 0%;
}

/* side-menu */
.side-menu {
    padding-top: 70% !important;
}

.side-menu .MuiMenuItem-root {
    padding: 3%;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 3% 5%;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
    transition: 0.5s;
    width: 80%;
}

.side-menu .MuiMenuItem-root:hover {
    background-color: #f0f0f0;
}

.side-menu .MuiMenuItem-root:hover .MuiTypography-subtitle1 {
    color: #ffffff !important;
}

/* inactive button */
.side-menu .inactive {
    filter: grayscale(100%);
    background-color: #f0f0f0;
}

.side-menu .inactive:hover {
    filter: grayscale(100%);
    background-color: #f0f0f0;
}

.category {
    transition: 0.5s;
    width: 100%;
}

.category:hover h6,
.category:hover .css-ptiqhd-MuiSvgIcon-root {
    color: #cf053e !important;
}

.how-it-works-section {
    background: #cf053e;
}

.institution-view {
    border-radius: 50px;
    margin: 3%;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
}

.institution-info {
    color: #000000;
    padding: 3% !important;
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

/*
*--------------------------------
* Managers Page
*---------------------------------
*/
/* welcome section */
.manager-welcome-section {
    background: url("https://ik.imagekit.io/9vm1rbs0vzz/public/WapiDoc_B5ZxB_hnp.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.local-reach {
    background: url("https://wapidoc.herokuapp.com/images/WapiDocLocalReach.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.impact {
    background: url("https://wapidoc.herokuapp.com/images/WapiDocPositiveImpact.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.analytics {
    background: url("https://wapidoc.herokuapp.com/images/WapiDocAnalytics.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.requirements {
    background: url("https://ik.imagekit.io/9vm1rbs0vzz/public/WapiDoc_B5ZxB_hnp.jpg");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

/*
* Careers Page
*/

.careers {
    background: url("https://wapidoc.herokuapp.com/images/careers.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}
.career-div {
    background-color: #ffffff;
    border-radius: 10px !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    padding: 3%;
    margin: 3%;
}

.single-view .MuiTypography-paragraph {
    color: #ffffff !important;
}

.card {
    z-index: 5;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin: 2%;
    padding: 1% 1.5%;
    background-color: #ffffff;
}

.css-j204z7-MuiFormControlLabel-root {
    padding: "10px" !important;
}

/*
* Footer Design and Sub Footer
*/
.footer {
    /* background-color: #19397d; */
    padding: 5% 5% 1% 5%;
    color: #333;
}

.footer .MuiListItem-gutters {
    padding: 2%;
}

.footer h6 {
    color: #333;
}

.footer a,
.sub-footer a {
    color: #333;
}

.footer a:hover,
.sub-footer a:hover {
    color: #cf053e;
}

.sub-footer .MuiListItemText-root {
    text-align: right !important;
}

.sub-footer .MuiListItem-gutters {
    padding: 0% !important;
}

.about-us {
    background: url("https://ik.imagekit.io/9vm1rbs0vzz/public/WapiDoc_B5ZxB_hnp.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.vision-mission {
    padding: 2%;
}

.vision-mission .MuiGrid-root {
    padding: 2% 2%;
}

.core-values {
    padding: 4% 2%;
}

.about-directory {
    background: url("https://wapidoc.herokuapp.com/images/WapiDocLocalReach.jpg");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.how-it-works .step-content {
    padding: 5%;
    text-align: center;
}

.how-it-works .step-content img {
    height: 350px;
}

/*
*------------------------------------
* INSTITUTION EDIT
*------------------------------------
*/

.mini-side-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.mini-side-menu .item,
.mini-side-menu .MuiListItemIcon-root {
    color: #324c74;
}

.mini-side-menu .border {
    border-right: 3px solid #324c74 !important;
}

/*
*--------------------------------------------
* GALLERY
*--------------------------------------------
*/
#imageuploadsection {
    background: url("https://ik.imagekit.io/9vm1rbs0vzz/public/BulkImageUpload_FWym-0Qju3I.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642468057296");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    border: 3px dotted #324c74;
    height: 50vh;
    width: 80%;
    margin: 2% auto;
    border-radius: 5px;
}

#imageupload {
    opacity: 0 !important;
    width: 62.5%;
    height: 50vh;
    position: fixed;
}

.gallery-image {
    position: relative;
}

.gallery-image .delete-button {
    position: absolute;
    top: 0;
    left: 5;
}

.gallery-image:hover .delete-button {
    color: red !important;
}

.gallery-image .button {
    font-size: 11px;
    width: 96% !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    position: absolute;
    bottom: 1.5%;
    left: 5;
}

.gallery-image .image-button {
    visibility: hidden;
}

.gallery-image:hover .image-button {
    visibility: visible;
}

/*
*---------------------
* SEARCH & FILTER BOX
*----------------------
*/
.filter {
    padding: 1%;
    overflow: auto;
    top: 0;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05) !important;
}

.search-results {
    height: 100vh;
    overflow-y: auto;
}

.search-bar {
    padding: 1%;
}

.search-bar button {
    width: 90%;
    padding: 6.8% 0%;
}

.search-bar .MuiFormControl-root {
    margin: 0% !important;
}

/*
*---------------
* PRICING TABLE
*---------------
*/
.pricing-table {
    padding: 10% 2% 0% 2%;
}

.price-card {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: #ffffff;
}

.pricing-table .header {
    background: url("https://ik.imagekit.io/9vm1rbs0vzz/public/wave_7ARTFSZ-z.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642916132295");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.pricing-table .MuiTabs-flexContainer {
    justify-content: center;
}

.single-tab .MuiTab-wrapper {
    align-items: left !important;
    display: inline-block !important;
}

.single-tab .Mui-selected .MuiSvgIcon-root,
.Mui-selected .MuiTab-wrapper {
    color: #cf053e !important;
}

.css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #cf053e !important;
    border-color: #cf053e;
}

/*
*--------------------------------------------
* MEDIA QUERIES
*--------------------------------------------
*/
@media screen and (max-width: 950px) {
    /* Home Page */
    .search-form {
        margin-top: 0% !important;
        width: 90% !important;
    }

    .search-form .MuiInputBase-input {
        padding: 5% 6%;
        margin: 0%;
    }

    .search-form .submit-button {
        padding: 16% 0% !important;
        margin-top: 5% !important;
    }

    .side-menu {
        padding-top: 50% !important;
    }

    .side-menu .MuiMenuItem-root {
        width: 90%;
        display: flex;
        margin: 2% auto;
        text-align: center;
    }

    .side-menu .MuiMenuItem-root h6 {
        padding-left: 0% !important;
    }

    /* About Us */
    .about-us {
        padding-top: 30%;
    }

    .about-us h2 {
        font-size: 3rem;
    }

    /* Managers */
    .manager-welcome-section {
        padding-top: 30%;
    }

    /*Requirements*/
    .requirements {
        padding-top: 40%;
    }

    .requirements h2 {
        font-size: 3rem;
    }

    .requirements-list {
        text-align: center;
    }

    /* How it Works */
    .how-it-works .step-content img {
        height: auto;
        width: 100%;
    }

    /* Careers */
    .careers {
        background-position: bottom center;
        background-size: 100%;
        padding-top: 40%;
    }

    /* welcome-section */
    .welcome-section {
        background: url("https://ik.imagekit.io/9vm1rbs0vzz/public/WapiDoc_Mobile_va7BPQCMW.jpg");
        background-position: top center;
    }

    /* Top Menu*/
    .wide-screen {
        display: none !important;
    }

    .small-screen {
        display: block;
    }

    .main-menu img {
        width: 120px !important;
    }

    .mobile-menu {
        padding: 2% 0% !important;
    }

    .mobile-menu .signin-button {
        padding: 2% 5% !important;
        margin: 0% 2% !important;
    }

    .wide-screen-section {
        display: none;
    }

    .signup-section {
        text-align: center !important;
    }

    .signup-section .MuiTypography-alignRight {
        text-align: left !important;
        margin-bottom: 3%;
    }

    .add-space {
        padding-top: 17% !important;
    }

    .small-spacing {
        padding: 0%;
    }

    .submit-button {
        padding: 3% 5% !important;
    }

    #hours button {
        padding: 5% 5% !important;
    }
    #hours .third-i {
        width: 100% !important;
    }

    .search-bar {
        padding: 1%;
    }

    .search-bar button {
        width: 10% !important;
        padding: 26.8% 0% !important;
    }

    .search-bar .MuiButton-root {
        min-width: 80%;
    }

    .search-bar button .MuiButton-endIcon {
        margin: 0% auto;
    }

    .search-bar .MuiInputBase-root {
        overflow-x: hidden !important;
    }

    .filter .MuiPaper-root {
        width: 80%;
        overflow-x: hidden;
    }
}

@media screen and (max-width: 600px) {
    .search-form {
        margin-top: 0% !important;
    }
}
